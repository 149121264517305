import axios from './BaseService';

const API_URL = process.env.REACT_APP_API_URL;
const BEHOLDER_URL = `${API_URL}/beholder/`;

export async function getIndexes() {
    const indexesUrl = `${BEHOLDER_URL}memory/indexes`;
    const response = await axios.get(indexesUrl);
    return response.data;
}

export async function getAnalysisIndexes(){
    const response = await axios.get(`${BEHOLDER_URL}analysis/`, );
    return response.data;
}

export async function getMemoryIndex(symbol, index, interval) {
    const response = await axios.get(`${BEHOLDER_URL}memory/${symbol}/${index}/${interval ? interval : ''}`);
    return response.data;
}


export async function getMemory() {
    
    const response = await axios.get(`${BEHOLDER_URL}memory/`);
    return response.data;
}

export async function getAgenda() {
    
    const response = await axios.get(`${BEHOLDER_URL}agenda/`);
    return response.data;
}

export async function getBrain() {
    
    const response = await axios.get(`${BEHOLDER_URL}brain/`);
    return response.data;
}