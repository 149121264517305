import axios from 'axios';

axios.interceptors.request.use(
    config => {
        config.headers.Authorization = localStorage.getItem('token');
        return config;
    },
    error => Promise.reject(error)
)

axios.interceptors.response.use(
    response => response,
    error => {
        if(error.response 
            && error.response.status === 401
            || error.response.status === 403
        ){
            console.log('Redirected to login by 40x response');
            window.location = '/'
        }
        else
            return Promise.reject(error);
    }
)

export default axios;